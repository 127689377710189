<!-- For use with CtCardDeck -->
<template>
  <li class="card" @click="$emit('click')">
    <slot />
  </li>
</template>

<script>

export default {
  name: 'CtCard',
}
</script>

<style lang="scss">
li.card {
  @include ct-ui-selectable-horizontal;
  @include ct-ui-card-shadow;
  * { cursor: pointer }
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 15px;
  max-height: 120px;
  width: 100%;
  padding: 10px;
  .router-link-active * {
    &:hover {
      text-decoration: none !important;
    }
  }
  .card__image {
    height: 78px;
    width: 101px;
    text-align: center;
    picture {
      .image {
        height: 78px;
        width: 101px;
      }
    }
  }
  .card__text {
    text-align: center;
    flex-grow: 1;
    color: black;
    &:hover {
      text-decoration: none !important;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    max-height: unset;
  }
}
</style>
